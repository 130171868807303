import tw from 'twin.macro'
import { useEffect } from 'react'
import StyledImage from '@/components/atoms/styled-image'
import Column from '@/components/atoms/column'
import Row from '@/components/atoms/row'

import PersonalColorItem from '../components/personal-color-item'
import ReviewCard from '../components/review-card'
import useMenu from '@/store/use-menu'
import { NextSeo } from 'next-seo'

export default function IntroducePage() {
  const { setMenu } = useMenu()

  useEffect(() => {
    setMenu('introduce')
  }, [setMenu])

  return (
    <>
      <NextSeo title="서비스 소개 - 코콘 소개" />

      <Column
        css={[
          tw`bg-[#E5F2FB]`,
          tw`mobile:(pt-[75px] pb-[79px])`,
          tw`desktop:(pt-[96px] pb-[91px] px-[100px] flex-row flex-center)`,
        ]}
        isSection
      >
        <div
          css={[
            tw`mobile:(pl-[20px] w-[234px] mb-[27px])`,
            tw`desktop:(w-[390px])`,
          ]}
        >
          <div
            css={[
              tw`font-bold pb-[15px]`,
              tw`mobile:(text-24pxr)`,
              tw`desktop:(text-40pxr)`,
            ]}
          >
            <div>내 손 안의</div>
            <div>AI 스타일리스트,</div>
            <div>코콘 COCON</div>
          </div>
          <p
            css={[
              tw`font-medium`,
              tw`mobile:(text-12pxr)`,
              tw`desktop:(text-20pxr)`,
            ]}
          >
            코콘 COCON은 자신의 본색 (True Color)대로 살 수 있는 세상을
            만듭니다.
          </p>
        </div>
        <StyledImage
          src="/service/introduce/thumbnail.png"
          alt="내 손 안의 AI 스타일리스트, 코콘 COCON"
          css={[
            tw`ml-auto`,
            tw`mobile:(h-[271px] w-[300px])`,
            tw`desktop:(w-[569px] h-[453px])`,
          ]}
        />
      </Column>
      <Column
        css={[
          tw`py-[75px]`,
          tw`mobile:(pt-[73px] pb-[84px] px-[21px])`,
          tw`desktop:(pt-[202px] pb-[208px] px-[100px])`,
        ]}
        isSection
      >
        <div
          css={[
            tw`font-bold text-24pxr `,
            tw`mobile:(pb-[12px])`,
            tw`desktop:(text-40pxr pb-[10px])`,
          ]}
        >
          <p>쉽고 빠르게 진단받는 퍼스널 컬러</p>
        </div>
        <Column
          css={[
            tw`font-medium`,
            tw`mobile:(text-grey-2 text-12pxr pb-[47px])`,
            tw`desktop:(flex-row text-20pxr pb-[93px])`,
          ]}
        >
          <div>3,200가지의 유형으로 퍼스널 컬러를 나누어 기존 </div>
          <div>사계절 유형 분류보다 800배 자세히 진단됩니다.</div>
        </Column>
        <Column css={[tw`mt-[10px]`, tw`desktop:(flex-row justify-between)`]}>
          <PersonalColorItem
            src="/service/introduce/personal-color/image1.png"
            alt="창문앞 휴대폰을 들고 있는 여성"
            title="사진 한 장"
            text="얼굴 사진을 촬영하여 퍼스널컬러를 분석해요."
          />
          <PersonalColorItem
            src="/service/introduce/personal-color/image2.png"
            alt="앱 내 컬러 팔레트"
            title="AI 패션 추천"
            text="내 퍼스널 컬러에 맞는 옷을 추천 받을 수 있어요."
          />
          <PersonalColorItem
            src="/service/introduce/personal-color/image3.png"
            alt="앱 내 진단결과 상세"
            title="자세한 4단계 분석"
            text="웜/쿨 외에도 옐로톤, 레드톤, 채도, 명도까지 세세하게"
          />
        </Column>
      </Column>
      <Column
        css={[
          tw`bg-[#DEDEDE]`,
          tw`mobile:(pt-[85px])`,
          tw`desktop:(pt-[173px] px-[100px] flex-row justify-between)`,
        ]}
        isSection
      >
        <div css={[tw`mobile:(mx-[20px])`, tw`desktop:(w-[363px] pt-[80px])`]}>
          <div
            css={[
              tw`font-bold`,
              tw`mobile:(text-24pxr mb-[12px])`,
              tw`desktop:(text-40pxr mb-[30px])`,
            ]}
          >
            <p>페이스 이미지 진단</p>
          </div>
          <div
            css={[
              tw`font-medium`,
              tw`mobile:(text-12pxr text-grey-2)`,
              tw`desktop:(text-20pxr w-[365px])`,
            ]}
          >
            <p>
              나의 얼굴 분위기와 얼굴형, 눈, 코, 입에 따른 섬새한 분석에
              어울리는 스타일 팁까지
            </p>
          </div>
        </div>
        <Row
          css={[
            tw`justify-between`,
            tw`mobile:(mt-[47px])`,
            tw`desktop:(w-[581px])`,
          ]}
        >
          <StyledImage
            src="/service/introduce/face-image/image1.png"
            alt="이미지 진단 상세 화면"
            css={[
              tw`mobile:(w-[179px] h-[330px])`,
              tw`desktop:(w-[275px] h-[466px])`,
            ]}
          />
          <StyledImage
            src="/service/introduce/face-image/image2.png"
            alt="이미지 진단 상세 화면"
            css={[
              tw`mobile:(w-[179px] h-[330px])`,
              tw`desktop:(w-[275px] h-[466px])`,
            ]}
          />
        </Row>
      </Column>
      <Column
        css={[
          tw`mobile:(py-[125px])`,
          tw`desktop:(px-[60px] flex-row justify-between items-center)`,
        ]}
        isSection
      >
        <div
          css={[
            tw`mx-[20px]`,
            tw`mobile:(pb-[45px])`,
            tw`desktop:(order-last ml-[100px])`,
          ]}
        >
          <div
            css={[
              tw`font-bold`,
              tw`mobile:(text-24pxr mb-[12px])`,
              tw`desktop:(text-40pxr mb-[30px])`,
            ]}
          >
            <div>내가 원하는 조건 필터</div>
          </div>
          <div>
            <div
              css={[
                tw`font-medium`,
                tw`mobile:(text-grey-2 text-12pxr)`,
                tw`desktop:(text-20pxr)`,
              ]}
            >
              <div>
                <Row css={[tw`desktop:(flex-col)`]}>
                  <div>진단 결과에 내 취향을 더해 </div>
                  <div>나와 딱 맞는 상품만 모아 보기</div>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <Row
          css={[tw`mobile:(hidden)`, tw`desktop:(w-[502px] justify-between)`]}
        >
          <StyledImage
            src="/service/introduce/filter/image1.png"
            alt="앱 내 카테고리 필터"
            css={[
              tw`mobile:(w-[179px] h-[330px])`,
              tw`desktop:(w-[236px] h-[486px])`,
            ]}
          />
          <StyledImage
            src="/service/introduce/filter/image2.png"
            alt="앱 내 카테고리 필터"
            css={[
              tw`mobile:(w-[179px] h-[330px])`,
              tw`desktop:(w-[236px] h-[486px])`,
            ]}
          />
        </Row>
        <StyledImage
          src="/service/introduce/filter/image5.png"
          alt="앱 내 카테고리 필터"
          css={[tw`mobile:(w-[375px] h-[552px])`, tw`desktop:(hidden)`]}
        />
      </Column>
      <Row
        css={[
          tw`mobile:(hidden)`,
          tw`desktop:(mx-[200px] justify-between items-center)`,
        ]}
        isSection
      >
        <StyledImage
          src="/service/introduce/filter/image3.svg"
          alt="보석"
          css={[
            tw`mobile:(w-[179px] h-[330px])`,
            tw`desktop:(w-[285px] h-[238px])`,
          ]}
        />
        <StyledImage
          src="/service/introduce/filter/image4.png"
          alt="보물찾기"
          css={[
            tw`mobile:(w-[179px] h-[330px])`,
            tw`desktop:(w-[236px] h-[486px])`,
          ]}
        />
      </Row>
      <Column
        css={[
          tw`bg-[#DEDEDE]`,
          tw`mobile:(pt-[86px])`,
          tw`desktop:(pt-[150px] pb-[194px] flex-row flex-center)`,
        ]}
        isSection
      >
        <div
          css={[
            tw`mobile:(mx-[20px] mb-[48px])`,
            tw`desktop:(w-[363px] ml-[180px] order-last)`,
          ]}
        >
          <div
            css={[
              tw`font-bold`,
              tw`mobile:(text-24pxr mb-[9px])`,
              tw`desktop:(text-40pxr mb-[30px])`,
            ]}
          >
            패션 피드
          </div>
          <div
            css={[
              tw`font-medium`,
              tw`mobile:(text-12pxr text-grey-2)`,
              tw`desktop:(text-20pxr)`,
            ]}
          >
            <Row css={[tw`desktop:(flex-col)`]}>
              <div>매일 매일 업데이트 되는 </div>
              <div>나만을 위한 찰떡 옷 추천</div>
            </Row>
          </div>
        </div>
        <StyledImage
          src="/service/introduce/feed/image1.png"
          alt="이미지 진단 상세 화면"
          css={[
            tw`mobile:(w-[286px] h-[911px] self-center)`,
            tw`desktop:(w-[405px] h-[1155px])`,
          ]}
        />
      </Column>
      <Column
        css={[
          tw`mobile:(pt-[125px]) pb-[67px]`,
          tw`desktop:(pt-[227px] pb-[158px] flex-center)`,
        ]}
        isSection
      >
        <Column
          css={[
            tw`font-bold`,
            tw`mobile:(text-24pxr mb-[12px] px-[20px])`,
            tw`desktop:(text-40pxr mb-[30px] flex-row)`,
          ]}
        >
          <div>기술로 개인의 의류 소비와</div>
          <div>생산 방식을 바꾼다</div>
        </Column>
        <div>
          <div
            css={[
              tw`font-medium text-grey-2`,
              tw`mobile:(text-12pxr mx-[20px])`,
              tw`desktop:(text-20pxr text-center)`,
            ]}
          >
            <Row css={[tw`desktop:(flex-col)`]}>
              <div>
                코콘은 AI를 기반으로 나에게 딱 맞는 옷만 구매할 수 있도록
                돕습니다.
              </div>
              <div>
                장기적으로는 개인의 신체 데이터를 기반으로 수요를 예측하여
              </div>
              <div>소수가 정하는 유행의 탑다운 방식이 아닌</div>
              <div>사용자 중심의 바텀업 방식의 시스템을 구축합니다.</div>
              <div>이를 통해 천편일률적 유행에 따른 소비의 틀을 깨고</div>
              <div>사용자 중심의 의류 소비로 전환하는 것을 목표로 합니다.</div>
            </Row>
          </div>
        </div>
        <Row css={[tw`flex-center`, tw`desktop:(mt-[40px])`]}>
          <StyledImage
            src="/service/introduce/objective/image1.svg"
            alt="깃발을 들고 있는 사람"
            css={[
              tw`mobile:(w-[145px] h-[270px])`,
              tw`desktop:(w-[224px] h-[416px] mx-[106px])`,
            ]}
          />
          <StyledImage
            src="/service/introduce/objective/image2.svg"
            alt="화살표"
            css={[tw`mobile:(hidden)`, tw`desktop:(w-[37px] h-[37px])`]}
          />
          <StyledImage
            src="/service/introduce/objective/image3.svg"
            alt="휴대폰을 들고 있는 사람"
            css={[
              tw`mobile:(w-[294px] h-[431px] self-center)`,
              tw`desktop:(w-[436px] h-[638px])`,
            ]}
          />
        </Row>
      </Column>
      <Column
        css={[
          tw`bg-[#DEDEDE]`,
          tw`mobile:(pt-[68px] pb-[68px] mb-[96px])`,
          tw`desktop:(py-[195px] justify-center mb-[194px])`,
        ]}
      >
        <div
          css={[
            tw`font-bold`,
            tw`mobile:(px-[20px] pb-[50px] text-24pxr )`,
            tw`desktop:(text-center pb-[93px] text-30pxr)`,
          ]}
        >
          <p>먼저 사용한 경험자의 찐후기</p>
        </div>
        <Row
          css={[
            tw`mobile:(px-[20px] overflow-x-auto flex-nowrap)`,
            tw`desktop:(justify-center)`,
          ]}
        >
          <ReviewCard platform="google">
            <span>
              너무 간단한 방법으로 제 퍼스널 컬러를 정확하게 알 수 있어서 너무
              좋아요.
            </span>
            <span>
              저는 <strong>어울리는 색을 찾는게 어려웠는데</strong> 사진 한
              장으로
              <strong>
                세부톤까지 알려주어서 옷이나 색조 화장품을 사는데도 많은 도움을
                받고 있어요.
              </strong>
            </span>
          </ReviewCard>
          <ReviewCard platform="apple">
            퍼스널컬러 진단은 받고 싶은데 비싸기 도 하고 피부색이 변함에 따라
            퍼스널 컬러가 바뀐다해서 실제로 적용 하기 고민되는데{' '}
            <strong>
              코콘 어플 덕분에 쉽게 제 퍼스널컬러에 대해 알게 되었어요! 다른
              어플보다 더 정확히 진단해줘서 너무 좋아요!
            </strong>
          </ReviewCard>
          <ReviewCard platform="google">
            다른 앱으로 진단하면 자꾸 퍼스널 컬러가 다르게 나와서 헷갈렸는데
            코콘이 해주는 진단은 정확하더라고요!! 그뿐만 아니라{' '}
            <strong>
              옷 추천, 페이스 이미지까지 알려줘서 지금 까지 깔아본 뷰티 앱 중
              최고라고 생각했습니다😄
            </strong>{' '}
            너무 만족해요!!
          </ReviewCard>
        </Row>
      </Column>
    </>
  )
}
