import tw from 'twin.macro'

function Row({
  children,
  isSection,
  isArticle,
  ...rest
}: React.PropsWithChildren<{ isSection?: boolean; isArticle?: boolean }>) {
  const css = tw`flex flex-row`

  if (isSection) {
    return (
      <section css={[css]} {...rest}>
        {children}
      </section>
    )
  }

  if (isArticle) {
    return (
      <article css={[css]} {...rest}>
        {children}
      </article>
    )
  }

  return (
    <div css={[css]} {...rest}>
      {children}
    </div>
  )
}

export default Row
