import Image from 'next/image'
import tw, { css } from 'twin.macro'

export default function ReviewCard({
  children,
  platform,
}: React.PropsWithChildren<{
  platform: 'google' | 'apple'
}>) {
  return (
    <div
      css={[
        tw`bg-white rounded-[14px] flex flex-col justify-between [flex: 0 0 auto]`,
        tw`mobile:(w-[175px] h-[203px] px-[13px] py-[17px] text-9pxr mx-[4.5px])`,
        tw`desktop:(w-[260px] h-[300px] px-[20px] py-[26px] text-14pxr mx-[6.5px])`,
      ]}
    >
      <span>
        <p>
          <strong css={tw`text-30pxr`}>“</strong>
        </p>
        <p
          css={[
            tw`text-grey-1`,
            css`
              & strong {
                color: black;
              }
            `,
          ]}
        >
          {children}
        </p>
      </span>
      <div css={tw`flex flex-row`}>
        {platform === 'google' ? (
          <>
            <Image
              src="/service/introduce/review/google-play.svg"
              alt="구글 플레이"
              width={26}
              height={26}
            />
            <p css={tw`ml-[8px]`}>Google Play</p>
          </>
        ) : (
          <>
            <Image
              src="/service/introduce/review/app-store.svg"
              alt="앱 스토어"
              width={26}
              height={26}
            />
            <p css={tw`ml-[8px]`}>App Store</p>
          </>
        )}
      </div>
    </div>
  )
}
