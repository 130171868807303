import tw from 'twin.macro'
import StyledImage from '@/components/atoms/styled-image'

export default function PersonalColorItem({
  src,
  alt,
  title,
  text,
}: {
  src: string
  alt: string
  title: string
  text: string
}) {
  return (
    <div
      css={[
        tw`flex flex-col justify-center w-[280px]`,
        tw`mobile:(w-[269px] pb-[33px])`,
        tw`desktop:(w-[280px])`,
      ]}
    >
      <StyledImage
        src={src}
        alt={alt}
        css={[
          tw`rounded-[24px]`,
          tw`mobile:(w-[269px] h-[240px])`,
          tw`desktop:(w-[280px] h-[308px])`,
        ]}
      />
      <p
        css={[
          tw`font-bold`,
          tw`mobile:(text-16pxr text-center mt-[9px])`,
          tw`desktop:(text-24pxr pt-[54px] pb-[17px])`,
        ]}
      >
        {title}
      </p>
      <p
        css={[
          tw`font-medium text-grey-1 text-12pxr`,
          tw`mobile:(text-center)`,
          tw`desktop:(text-20pxr)`,
        ]}
      >
        {text}
      </p>
    </div>
  )
}
