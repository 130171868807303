import tw from 'twin.macro'

function Column({
  children,
  isSection,
  isArticle,
  ...rest
}: React.PropsWithChildren<{
  isSection?: boolean
  isArticle?: boolean
}>) {
  const css = tw`flex flex-col`

  if (isSection) {
    return (
      <section css={[css]} {...rest}>
        {children}
      </section>
    )
  }

  if (isArticle) {
    return (
      <article css={[css]} {...rest}>
        {children}
      </article>
    )
  }

  return (
    <div css={[css]} {...rest}>
      {children}
    </div>
  )
}

export default Column
